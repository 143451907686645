import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "development"
    }}>{`Development`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <iframe src="https://giphy.com/embed/scZPhLqaVOM1qG4lT9" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    <h2 {...{
      "id": "prerequistes-"
    }}>{`Prerequistes 🧰`}</h2>
    <ul>
      <li parentName="ul">{`Make sure you have `}<inlineCode parentName="li">{`nvm`}</inlineCode>{` installed. Docs: `}<a parentName="li" {...{
          "href": "https://github.com/nvm-sh/nvm#installing-and-updating"
        }}>{`nvm installion`}</a></li>
      <li parentName="ul">{`Make sure you have node installed. Docs: `}<a parentName="li" {...{
          "href": "https://nodejs.org/en"
        }}>{`nodeJS`}</a></li>
    </ul>
    <p>{`To start up this project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`1. Clone repo
2. run \`nvm use\` - Make sure you have the correct node version installed, if not install it.
3. run \`npm ci\`
4. run \`npm run dev\`
5. Open http://localhost:3000/ in browser
`}</code></pre>
    <h2 {...{
      "id": "running-tests-"
    }}>{`Running tests 👟`}</h2>
    <p>{`For running all tests:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm run test
`}</code></pre>
    <p>{`For running only unit tests:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm run test:jest
`}</code></pre>
    <p>{`For running unit tests in only 1 package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm run test:jest -- --scope=@uireact/THE-PACKAGE-NAME
`}</code></pre>
    <p>{`For running unit tests in only 1 file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npx jest --coverage=false -- RELATIVE-PATH-TO-TEST-FILE
`}</code></pre>
    <p>{`👁️ `}<strong parentName="p">{`--coverage=false`}</strong>{` this flag will skip coverage, useful when developing but coverage is needed for PR check to pass`}</p>
    <h2 {...{
      "id": "test-coverage-️"
    }}>{`Test coverage 🏖️`}</h2>
    <p>{`We currently have set up coverage to 100%.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`branches: 100,
functions: 100,
lines: 100,
statements: 100
`}</code></pre>
    <iframe src="https://giphy.com/embed/XTQZjxl8BW0QE" width="300" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      